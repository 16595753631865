import { Button } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { activateCard, deleteCard, existingCardList } from "../../../api/billingApi";
import BasicInput from "../../Common/BasicInput";
import CustomConfirmAlert from "../../Common/CustomConfirmAlert";
import Loader from "../../Common/Loader";

const ExitingCards = (props) => {
    const [componentLoading, setComponentLoading] = useState(true);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        existingCardList({})
        .then(response => {
            setData(response.data.data);
        }).finally(() => {
            setComponentLoading(false);
        });
    }

    const makeDefault = (_id) => {

        CustomConfirmAlert({
            handleConfirm : () => {
                activateCard({
                    "form_data": {
                        card_id: _id
                    }
                })
                    .then(response => {
                        props.callback();
                    });
            },
            handleOnClose: () => {},
            title: 'Are you sure?',
            description: 'Do you want to make this card default?',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes',
            minHeight: '200px'
        });
    }

    const deleteData = (_id) => {

        CustomConfirmAlert({
            handleConfirm : () => {
                deleteCard({
                    "form_data": {
                        card_id: _id
                    }
                })
                    .then(response => {
                        props.callback();
                    });
            },
            handleOnClose: () => {},
            title: 'Are you sure?',
            description: 'Do you want to delete this card?',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes',
            minHeight: '200px'
        });
    }

    return (
        <Fragment>
            {
                componentLoading ? (
                    <div style={{paddingTop: 20, paddingLeft: 100}}>
                        <Loader/>
                    </div>
                ) : (
                   <table class="table">
                        <tbody>
                        {
                            data.map((item, index) => (
                                <tr style={{borderBottom: 'none'}}>
                                    <th>
                                        <BasicInput 
                                            disabled 
                                            placeholder={`Card Number **** **** **** ${item.card_number}`}
                                        />
                                    </th>
                                    {
                                        (item.status !== 1) && (
                                            <td>
                                                <Button variant="contained" className="mr-2 accent--bg--color" onClick={() => makeDefault(item.id)}>
                                                    Make Default
                                                </Button>
                                                <Button variant="contained" color="secondary" onClick={() => deleteData(item.id)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                        
                        </tbody>
                    </table>
                )
            }
        </Fragment>
    )
}

export default ExitingCards;