import { Button, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { getInstantTopupSettings, getRechargeSettings, updateRechargeSettings } from "../../api/billingApi";
import { getAccountData } from "../../helpers/Utils";
import { Alert } from "@material-ui/lab";
import { LAYOUT_SETTINGS } from "../../constants/CoreConstants";
import CustomSwitch from "../Common/Switch";
import DynamicSkeleton from "./BottomPart/DynamicSkeleton";
import Constants from "./Constants";

const useStyles = makeStyles((theme) => ({
    bottomContainer: {
        display: "flex",
        width: "100%",
        gap: 40,
        padding: "10px 20px",
    },
    notesList: {
        fontSize: 14,
        width: "100%",
        paddingLeft: theme.spacing(2.25),
        "& li": {
          listStyleType: "disc !important",
        },
        "& svg.MuiSvgIcon-root": {
           color: "#2196f3 !important",
        }, 
    },
    actionContainer:{
      display: "flex",
      flexDirection: "column",
      gap:10
    },
}));

const options = [
    // { value: 10, label: '$10' },
    { value: 25, label: '$25' },
    { value: 50, label: '$50' },
    { value: 75, label: '$75' },
    { value: 150, label: '$150' },
    { value: 200, label: '$200' },
    { value: 250, label: '$250' },
    { value: 500, label: '$500' },
    { value: 1000, label: '$1000' },
    { value: 1500, label: '$1500' },
    { value: 2000, label: '$2000' },
    { value: 2500, label: '$2500' },
    { value: 3000, label: '$3000' },
    { value: 3500, label: '$3500' },
    { value: 4000, label: '$4000' },
    { value: 4500, label: '$4500' },
    { value: 5000, label: '$5000' },
    { value: 5500, label: '$5500' },
    { value: 6000, label: '$6000' },
    { value: 6500, label: '$6500' },
    { value: 7000, label: '$7000' },
    { value: 7500, label: '$7500' },
    { value: 8000, label: '$8000' },
    { value: 8500, label: '$8500' },
    { value: 9000, label: '$9000' },
    { value: 9500, label: '$9500' },
    { value: 10000, label: '$10000' }
]

const AutoRecharge = (props) => {
    const classes = useStyles();
    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [refillAmount, setRefillAmount] = useState(options[0].value);
    const [creditReach, setCreditReach] = useState(options[0].value);
    const [refillStatus, setRefillStatus] = useState(false);
    const [customer, setCustomer] = useState(null);


    useEffect(() => {
        loadData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps 

    const loadData = () => {
        setComponentLoading(true)
        getInstantTopupSettings({})
            .then(response => {
                setCustomer(response.data.data);
                if (response.data.data !== false) {
                    getRechargeSettings({})
                        .then(response => {
                            const result = response.data.data

                            let tmpRefillAmount;
                            let tmpCreditReach;
                            if (typeof result.refill_amount !== 'undefined') {
                                if (result.refill_amount === "") {
                                    tmpRefillAmount = options[0].value;
                                    setRefillAmount(tmpRefillAmount);
                                }
                                else {
                                    tmpRefillAmount = result.refill_amount;
                                    setRefillAmount(tmpRefillAmount);
                                    setRefillStatus(true);
                                }
                            }
                            if (typeof result.credit_reach !== 'undefined') {
                                if (result.credit_reach === "") {
                                    tmpCreditReach = options[0].value;
                                    setCreditReach(tmpCreditReach);
                                }
                                else {
                                    tmpCreditReach = result.credit_reach;
                                    setCreditReach(tmpCreditReach);
                                }
                            }

                            if (props.tab && props.tab === Constants.TAB_AUTO_RECHARGE_WITH_ACTIVATION) {
                                submitData(tmpRefillAmount, tmpCreditReach);
                            }
                        })
                }
            }).finally(() => {
                setComponentLoading(false);
            });
    }

    const submitData = (tmpRefillAmount, tmpCreditReach) => {
        setLoading(true);
        setRefillStatus(true);
        updateRechargeSettings({
            "form_data": {
                refill_amount: (tmpRefillAmount === null ? '' : parseInt(tmpRefillAmount)),
                credit_reach: (tmpCreditReach === null ? '' : parseInt(tmpCreditReach)),
            }
        }).then((response) => {
            // setRefillStatus(true);
            if (response.data.status !== 'success') {
                setRefillStatus(false);
            }
            else
                window.showNotification("SUCCESS", response.data.message)
        }).finally(() => {
            setLoading(false);
        }).catch(error => {
            setRefillStatus(false);
        })
    }

    const removeLoading = () => {
        setRefillStatus(false);
        updateRechargeSettings({
            "form_data": {}
        }).then((response) => {
            // setRefillStatus(false);
            if (response.data.status !== 'success') {
                setRefillStatus(true);
            } else
                window.showNotification("SUCCESS", response.data.message)
        }).catch(error => {
            setRefillStatus(true);
        })
    }

    let brandColor = '#27AE60';
    let fetchBrandColor = getAccountData('layoutSettings');
    if (
      fetchBrandColor !== undefined &&
      fetchBrandColor[LAYOUT_SETTINGS.ACCENT_COLOR]
    ) {
      brandColor = fetchBrandColor[LAYOUT_SETTINGS.ACCENT_COLOR];
    }

    return (
      <Fragment>
        {componentLoading ? (
          <DynamicSkeleton count={3} />
        ) : (
          <Fragment>
            {props.isTrial ? (
              <div style={{ paddingTop: 30 , maxWidth: "600px" }}>
                <Alert severity="error">
                  This feature is not available for you currently. You are in
                  trial mode
                </Alert>
              </div>
            ) : customer && typeof customer.id !== "undefined" ? (
              <div id="recharge_setting" class="col s12">
                <div>
                  <div style={{ padding: "10px 20px" }}>
                    Auto Recharge is -{" "}
                    <CustomSwitch
                      status={refillStatus}
                      brandColor={brandColor}
                      onChange={
                        refillStatus
                          ? () => removeLoading()
                          : () => submitData(refillAmount, creditReach)
                      }
                    />
                  </div>
                  <div className={classes.bottomContainer}>
                    <div style={{ width: "100%" }} className={classes.actionContainer}>
                      <div>
                        <p className="mt-1"> Recharge my account automatically to this amount</p>
                        <div styles={{ width: "100%" }}>
                          <Select
                            options={options}
                            isClearable={false}
                            isDisabled={!refillStatus}
                            value={{
                              value: refillAmount
                                ? refillAmount
                                : options[0].value,
                              label: `$${
                                refillAmount ? refillAmount : options[0].value
                              }`,
                            }}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999999,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                width: "50px",
                                "& > div:last-child": {
                                  height: "45px",
                                },
                              }),
                            }}
                            onChange={(_selectedOption) => {
                              setRefillAmount(_selectedOption.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="mt-1">When my dollar balance is equal or below</p>
                        <div styles={{ width: "100%" }}>
                          <Select
                            options={options}
                            isClearable={false}
                            isDisabled={!refillStatus}
                            value={{
                              value: creditReach
                                ? creditReach
                                : options[0].value,
                              label: `$${
                                creditReach ? creditReach : options[0].value
                              }`,
                            }}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999999,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                width: "50px",
                                "& > div:last-child": {
                                  height: "45px",
                                },
                              }),
                            }}
                            onChange={(_selectedOption) => {
                              setCreditReach(_selectedOption.value);
                            }}
                          />
                        </div>
                      </div>
                      <div class={`${refillStatus ? "" : "d-none"}`}>
                        <Button
                          className={
                            "mr-2 accent--bg--color accent--bg--text--color"
                          }
                          variant="contained"
                          disabled={loading}
                          onClick={submitData}
                        >
                          {!loading
                            ? "Save Auto Recharge Setting"
                            : "Saving..."}
                        </Button>
                      </div>
                    </div>
                    {refillAmount && creditReach && (
                      <div class="col s12">
                        <Alert severity="info" className={classes.notesList}>
                          <span style={{ fontWeight: 600 }}>
                            Auto-Recharge Settings:
                          </span>
                          <ul style={{paddingLeft: "10px",margin:"5px"}}>
                            <li>
                              Your account will automatically refill with $
                              {refillAmount} when your balance falls below $
                              {creditReach}.
                            </li>
                            <li>
                              This refill happens instantly, keeping your
                              account active.
                            </li>
                            <li>
                              Your saved card will be charged automatically for this amount.
                            </li>
                            <li>
                              No need for manual intervention - it's completely
                              automated!
                            </li>
                            <li>
                              After refilling, you'll see your updated balance immediately.
                            </li>
                          </ul>
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ paddingTop: 30 , maxWidth: "600px" }}>
                <Alert severity="error">
                  This feature is not available for you currently. Please add
                  card information.
                </Alert>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user,
        isTrial: state.billingReducer.isTrial
    }
}
const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoRecharge);