import { Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import Select from 'react-select';
import { fetchDetails } from "../../../actions/billingAction";
import { getInstantTopupSettings, getInstantTopupSettingsData, rechargeInstantTopup } from "../../../api/billingApi";
import * as Constants from "../Constants";
import CustomConfirmModal from "./CustomConfirmModal";
import DynamicSkeleton from "./DynamicSkeleton";

const useStyles = makeStyles((theme) => ({
    bottomContainer: {
        display: "flex",
        width: "100%",
        gap: 40,
        padding: "10px 20px",
    },
    notesList: {
        fontSize: 14,
        width: "100%",
        paddingLeft: theme.spacing(2.25),
        "& li": {
          listStyleType: "disc !important",
        },
        "& svg.MuiSvgIcon-root": {
           color: "#2196f3 !important",
        },                          
    }
}));

const options = [
    { value: 10, label: '$10' },
    { value: 25, label: '$25' },
    { value: 50, label: '$50' },
    { value: 75, label: '$75' },
    { value: 150, label: '$150' },
    { value: 200, label: '$200' },
    { value: 250, label: '$250' },
    { value: 500, label: '$500' },
    { value: 1000, label: '$1000' },
]

const InstantTopup = (props) => {
    const classes = useStyles();
    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [value, setValue] = useState(10);
    const [selectTopup, setSelectTopup] = useState(options);
    const confirmModalImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/billing/1.0.0/card_icon.png`;
    const descriptionInstantTopUpBottom = "The credits will be added to your account as soon as the payment is processed."
    const descriptionInstantTopUp = `By clicking I agree, you authorize us to charge your card in the amount of $${value}.`
    const descriptionAutoRecharge = "By clicking I agree, you authorize us to automatically recharge your card when your balance falls below your minimum threshold.";

    useEffect(() => {
        loadData();
        loadTopupData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = () => {
        getInstantTopupSettings({})
            .then(response => {
                setCustomer(response.data.data);
            });
    }

    const loadTopupData = () => {
        setComponentLoading(true)
        getInstantTopupSettingsData({})
            .then(response => {
                if (response.data.data !== null && response.data.data !== "") {
                    const op = response.data.data.value;
                    const myArray = op.split(",");
                    const values = [];
                    myArray.forEach((item) => {
                        values.push({ value: parseInt(item), label: `$${parseInt(item)}` });
                    });
                    if (myArray.length > 0) {
                        setValue(values[0].value);
                    }

                    setSelectTopup(values);
                }
                else {
                    setSelectTopup(options);
                }

            }).finally(() => {
                setComponentLoading(false);
            });
    }
    const requestAutoRecharge = () => {
        confirmAlert({
          customUI: ({ onClose }) => (
            <CustomConfirmModal
              title="Payment Authorization"
              onClose={onClose}
              handleConfirm={() => {
                props.setTab(Constants.TAB_AUTO_RECHARGE_WITH_ACTIVATION);
              }}
              closeButtonText="Cancel"
              confirmButtonText="I Agree"
              showConfirmButton={true}
              showCloseButton={true}
              description={descriptionAutoRecharge}
              imgSrc= {confirmModalImg}
            />
          ),
          closeOnClickOutside: false,
        });
      };

    const submitData = () => {
        confirmAlert({
          customUI: ({ onClose }) => (
            <CustomConfirmModal
              title="Payment Authorization"
              onClose={onClose}
              handleConfirm={async () => {
                setLoading(true);

                rechargeInstantTopup({
                  "form_data": {
                    refill_amount: value
                  }
                }).then((response) => {
                  let res = response.data;
                  if (res.data.status !== undefined && res.data.status === 'error') {
                    window.showNotification('ERROR', res.data.html);
                    setLoading(false);
                    return;
                  }
                  window.showNotification('SUCCESS', response.data.message);

                  if (res.data.auto_recharge_status !== undefined && !res.data.auto_recharge_status) {
                    requestAutoRecharge();
                  }

                  props.fetchDetails();
                  setLoading(false);
                  if (window.updateCurrentCredit !== undefined) {
                    window.updateCurrentCredit();
                  }
                }).catch((e) => {
                  setLoading(false);
                });
              }}
              closeButtonText="Cancel"
              confirmButtonText="I Agree"
              showConfirmButton={true}
              showCloseButton={true}
              description={descriptionInstantTopUp}
              descriptionBottom={descriptionInstantTopUpBottom}
              imgSrc={confirmModalImg}
            />
          ),
          closeOnClickOutside: false,
        });
      };
      
        const renderContent = () => {
        if (componentLoading || !props.user) {
            return <DynamicSkeleton count={3} />;
        }
        if ((!customer || typeof customer.id === "undefined")) {
            return (
                <div style={{ paddingTop: 30 , maxWidth:"600px"}}>
                    <Alert severity="error">
                        This feature is not available for you currently. Please add card information.
                    </Alert>
                </div>
            );
        }
        if (props.isTrial) {
            return (
                <div style={{ paddingTop: 30, maxWidth:"600px"}}>
                    <Alert severity="error">
                        This feature is not available for you currently. You are in trial mode
                    </Alert>
                </div>
            );
        }
        

        return (
            <div id="instant_top_up" className="col s12 mt-3">
                <div className="row">
                    <div className={classes.bottomContainer}>
                        <div style={{ width: "100%" }}>
                            <p className="mb-2">Recharge my account to this amount</p>

                            <div style={{ width: "100%" }}>
                                <Select
                                    options={selectTopup}
                                    isClearable={false}
                                    value={{
                                        value: value,
                                        label: `$${value}`,
                                    }}
                                    styles={{
                                        width: "100px",
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999999,
                                        }),
                                        valueContainer: (provided) => ({
                                            ...provided,
                                            width: "50px",
                                            "& > div:last-child": {
                                                height: "45px",
                                            },
                                        }),
                                    }}
                                    onChange={(_selectedOption) => {
                                        setValue(_selectedOption.value);
                                    }}
                                />
                            </div>

                            <div className="mt-2">
                                <Button
                                    variant="contained"
                                    className={"accent--bg--color"}
                                    disabled={loading}
                                    onClick={submitData}
                                >
                                    <span className={"accent--text--color"}>
                                        {!loading ? "Recharge" : "Recharging..."}
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <Alert
                            severity="info"
                            sx={{ mb: 2 }}
                            className={classes.notesList}
                        >
                            <span style={{ fontWeight: 600 }}>
                                Instant Top-Up Process:
                            </span>
                            <ul style={{ paddingLeft: "10px", margin: "5px" }}>
                                <li>
                                    Your saved card will be used for instant recharging.
                                </li>
                                <li>
                                    The selected amount (${value}) will be added to your
                                    account right away.
                                </li>
                                <li>
                                    You won't need to go through extra verification steps.
                                </li>
                                <li>
                                    This transaction will only charge your card once.
                                </li>
                                <li>
                                    After recharging, you'll see your updated balance.
                                </li>
                            </ul>
                        </Alert>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {renderContent()}
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user,
        isTrial: state.billingReducer.isTrial
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchDetails: (params) => dispatch(fetchDetails(params)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantTopup);